import React from 'react';
import SiteSettings from './components/SiteSettings/SiteSettings'
import Home from './components/Home/Home'
import Work from './components/Work/Work'
import Grade from './components/Work/Grade'
import About from './components/About/About'
import Contact from './components/Contact/Contact'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';

function App() {

  return (
    <div className="App">
      <Router>
        <SiteSettings>
          <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/work">
            <Work />
          </Route>
          <Route path="/grading">
            <Grade />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        </SiteSettings>
      </Router>
    </div>
  );
}

export default App;
