import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class VimeoPlayer extends React.Component {

  constructor(props) {
    super(props);

    this.vimeo = React.createRef();
  }

  componentDidMount() {
    this.vimeo.current.container.style.opacity = 0;
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = e => {
    const container = this.vimeo.current.container;
    const iframe = container.getElementsByTagName('iframe')[0];
		const aspect = iframe.offsetWidth / iframe.width;
		iframe.style.height = (Math.round(iframe.height * aspect)) + 'px';
  }

  render() {
    return (
      <Vimeo
        video={this.props.video}
        width="1920"
        ref={this.vimeo}
        onLoaded={() => {
          this.vimeo.current.container.style.opacity = 1;
          this.onResize()
        }}
      />
    )
  }
}

export default VimeoPlayer;
