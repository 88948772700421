import React from 'react'
import { useParams } from "react-router-dom";
import VimeoPlayer from '../VimeoPlayer'
import YoutubePlayer from '../YoutubePlayer'
import BlockContent from '@sanity/block-content-to-react'
import Media from '../Media'

import './case.scss'

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
   imageAsset: ({ node: { image, width, url } }) => {
     return (
         <Media src={image} alt={'image'} width={width} />
     )
   },
   vimeoEmbed: ({ node: { title, id }}) => (
     <VimeoPlayer video={id} />
   ),
   youtubeEmbed: ({ node: { title, id }}) => (
     <YoutubePlayer video={id} />
   )
 }
}

const Case = ({cases}) => {

  let { id } = useParams();
  let current = (cases.filter(c => c.slug.current === id));
  current = current[0];
  console.log(cases, id, current)

  return (
    <>
      {current &&
        <div className="case">
          <div className="case-content">
            <BlockContent blocks={current.content} serializers={serializers} />
          </div>
        </div>
      }
    </>
  );
}

export default Case;
