import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import Media from '../Media'
import client from '../../client'
import groq from 'groq';

import './contact.scss'

const serializers = {
  types: {
    image: ({ node: { image, width } }) => {
     return (
        <Media src={image} alt={'image'} width={width} />
      )
    }
  }
}

export default class Contact extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      content: []
    }
  }

  componentDidMount = async () => {
    const result = await client.fetch(groq`*[_type == "contact"][0]`);

    this.setState({
      content: result.content
    })
  }

  render() {
    return (
      <div className="contact">
        <div>
          <BlockContent blocks={this.state.content} serializers={serializers} />
        </div>
      </div>
    )
  }
};
