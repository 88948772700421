import React from 'react';
import Media from '../Media';
import { Link } from 'react-router-dom'

import './caseThumbnail.scss'

const CaseThumbnail = ({type, thumbnail, client, title, year, slug}) => (
  <div className="case-thumbnail">
    <Link to={`/${type}/${slug}`}>
      <div className="case-content">
        <Media src={thumbnail} width="230" alt={title} />
        <div className="desc">
          <span className="title">{title}</span><br />
          <span className="client">{client}</span>
        </div>
      </div>
    </Link>
  </div>
)

export default CaseThumbnail;
