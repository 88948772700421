import React from 'react';
import CaseThumbnails from './CaseThumbnails'
import Case from './Case'
import client from '../../client';
import groq from 'groq';
import { Switch, Route } from "react-router-dom";

import './work.scss'

export default class Work extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cases: []
    }
  }

  componentDidMount = async () => {
    const result = await client.fetch(groq`*[_type == "work"][0]{
      ...,
      cases[]->{
        ...,
        content[]{
          ...,
          "asset": asset->
        }
      }
    }`);

    this.setState({
      cases: result.cases
    })
  }

  render() {
    return (
      <div className="work">
        <Switch>
          <Route path="/work/:id" children={<Case cases={this.state.cases}/>} />
          <Route path="/work" children={<CaseThumbnails cases={this.state.cases}/>} />
        </Switch>
      </div>
    )
  }
};
