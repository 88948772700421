import React from 'react';
import imageUrlBuilder from '@sanity/image-url'
import SanityMuxPlayer from 'sanity-mux-player'
import client from '../client';

const builder = imageUrlBuilder(client);

function url(source) {
  return builder.image(source)
}

const Media = ({ type, src, width, height, ...rest }) => {

  if (!src) return '';

  switch (type) {
    case 'video':
      return <SanityMuxPlayer
        assetDocument={src}
        autoload={true}
        autoplay={true}
        showControls={false}
        muted={true}
        loop={false}
        playsInline={true}
        style={{}}
        poster={false}
        {...rest}
      />
    default:
      return <img src={
        url(src.asset)
          .width(!isNaN(width) ? width * 3 : undefined)
          .height(!isNaN(height) ? height * 3 : undefined)
          .url()
        } width={ width } height={ height } alt="Bild" {...rest} />
  }
}

export default Media;
