import React from 'react'
import YouTube from 'react-youtube';

class YoutubePlayer extends React.Component {

  constructor(props) {
    super(props);

    this.ytplayer = React.createRef();
  }

  componentDidMount() {
    this.ytplayer.current.style.opacity = 0;
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = e => {
    // console.log(.getElementsByTagName('iframe')[0]);
    // const container = this.ytplayer.current.container;
    const iframe = this.ytplayer.current.getElementsByTagName('iframe')[0];
		const aspect = iframe.offsetWidth / iframe.width;
		iframe.style.height = (Math.round(iframe.height * aspect)) + 'px';
  }

  parseURL = str => {
    let id;
    if (str.indexOf('watch?v=') > -1) id = str.split('watch?v=')[1];
    else if (str.indexOf('/') > -1) {
      const split = str.split('/');
      id = split[split.length -1];
    } else id = str;
    console.log(id);
    return id;
  }

  render() {
    const opts = {
			height: '1080',
			width: '1920',
			playerVars: { // https://developers.google.com/youtube/player_parameters
				autoplay: 0
			}
		};
    return (
      <div ref={ this.ytplayer }>
        <YouTube
          videoId={ this.parseURL(this.props.video) }
          opts={ opts }
          onReady={() => {
            this.ytplayer.current.style.opacity = 1;
            this.onResize()
          }}
        />
      </div>
    )
  }
}

export default YoutubePlayer;
