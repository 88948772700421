import React from 'react';
import Header from '../Header/Header'
import client from '../../client';
import groq from 'groq';
import { useLocation } from 'react-router-dom';

import './siteSettings.scss';

function PathCheck(props) {
  // If we're at the start page, don't display header
  if (useLocation().pathname !== '/') return props.children;
  return null;
}

export default class SiteSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bg: '#FFF',
      logo: {},
      mobileLogo: {}
    }
  }

  componentDidMount = async () => {
    const result = await client.fetch(groq`*[_type == "siteSettings"][0]`);

    this.setState({
      bg: result.bgColor.hex,
      logo: result.headerLogo,
      mobileLogo: result.headerLogoMobile
    })
  }

  render() {
    return(
      <div className="container" style={{background: this.state.bg}}>
        <div className="content">
          <PathCheck>
            <Header desktop={ this.state.logo } mobile={ this.state.mobileLogo } />
          </PathCheck>
          {this.props.children}
        </div>
      </div>
    )
  }
}
