import React from 'react';
import CaseThumbnail from './CaseThumbnail'

const CaseThumbnails = ({type = 'work', cases}) => {
  return (
    <>
    {cases.map(({thumbnail, client, title, year, slug}, index) => (
        <CaseThumbnail
          key={index}
          type={type}
          thumbnail={thumbnail}
          client={client}
          title={title}
          year={year}
          slug={slug.current}
        />)
    )}
    </>
  )
}

export default CaseThumbnails
