import React from 'react'
import Media from './Media'
import { Fade } from 'react-slideshow-image';

const fadeProperties = {
  duration: 2500,
  transitionDuration: 0,
  infinite: true,
  indicators: false,
  arrows: false
}

const Slideshow = ({images}) => {
  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
        {images.map((image, idx) => (
          <div key={idx} className="each-fade">
            <div className="image-container">
              <Media src={image} />
            </div>
          </div>
        ))}
      </Fade>
    </div>
  )
}

export default Slideshow;
